import React, { FC } from 'react'

import { ApolloLogo } from '../../img/logos/ApolloLogo/ApolloLogo'
import CSharpLogo from '../../img/logos/CSharpLogo/CSharpLogo'
import CouchDBLogo from '../../img/logos/CouchDBLogo/CouchDBLogo'
import CypressLogo from '../../img/logos/CypressLogo/CypressLogo'
import { DefenceDigitalLogo } from '../../img/logos/DefenceDigitalLogo/DefenceDigitalLogo'
import { DockerLogo } from '../../img/logos/DockerLogo/DockerLogo'
import { ElasticSearchLogo } from '../../img/logos/ElasticSearchLogo/ElasticSearchLogo'
import FigmaLogo from '../../img/logos/FigmaLogo/FigmaLogo'
import FreemarkerLogo from '../../img/logos/FreemarkerLogo/FreemarkerLogo'
import { GraphQLLogo } from '../../img/logos/GraphQLLogo/GraphQLLogo'
import JqueryLogo from '../../img/logos/JqueryLogo/JqueryLogo'
import LeafletLogo from '../../img/logos/LeafletLogo/LeafletLogo'
import NodeJSLogo from '../../img/logos/NodeJSLogo/NodeJSLogo'
import OnsenUI from '../../img/logos/OnsenUILogo/OnsenUILogo'
import PercyLogo from '../../img/logos/PercyLogo/PercyLogo'
import PouchDBLogo from '../../img/logos/PouchDBLogo/PouchDBLogo'
import ReactLogo from '../../img/logos/ReactLogo/ReactLogo'
import ReactTestingLibraryLogo from '../../img/logos/ReactTestingLibraryLogo/ReactTestingLibraryLogo'
import ReduxLogo from '../../img/logos/ReduxLogo/ReduxLogo'
import StorybookLogo from '../../img/logos/StorybookLogo/StorybookLogo'
import TypeScriptLogo from '../../img/logos/TypeScriptLogo/TypeScriptLogo'
import styled from '@emotion/styled'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 80px));
  gap: 1rem;
  justify-content: space-between;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(80px, 100px));
  }
`

const CodeLibContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  grid-template-rows: 80px auto;

  @media (min-width: 768px) {
    grid-template-rows: 150px auto;
  }
`

const LogoContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 1rem;
  box-sizing: border-box;
`

const Title = styled.div`
  text-transform: capitalize;
  font-weight: bold;
  text-align: center;
`

const libraryInfoSelector = (library?: string) => {
  switch (library?.toLowerCase()) {
    case 'react':
      return { Logo: ReactLogo }
    case 'redux':
    case 'redux toolkit':
    case 'react redux':
      return { Logo: ReduxLogo }
    case 'pouchdb':
      return { Logo: PouchDBLogo }
    case 'couchdb':
      return { Logo: CouchDBLogo }
    case 'onsenui':
      return { Logo: OnsenUI }
    case 'jquery':
      return { Logo: JqueryLogo }
    case 'leaflet':
      return { Logo: LeafletLogo }
    case 'nodejs':
      return { Logo: NodeJSLogo }
    case 'storybook':
      return { Logo: StorybookLogo }
    case 'cypress':
    case 'cypress.io':
      return { Logo: CypressLogo }
    case 'freemarker':
      return { Logo: FreemarkerLogo }
    case 'percy':
    case 'percy.io':
      return { Logo: PercyLogo }
    case 'figma':
      return { Logo: FigmaLogo }
    case 'react testing library':
      return { Logo: ReactTestingLibraryLogo }
    case 'c#':
      return { Logo: CSharpLogo }
    case 'typescript':
      return { Logo: TypeScriptLogo }
    case 'apollo client':
    case 'apollo server':
      return { Logo: ApolloLogo }
    case 'graphql':
      return { Logo: GraphQLLogo }
    case 'docker':
      return { Logo: DockerLogo }
    case 'elastic search':
      return { Logo: ElasticSearchLogo }
    case 'defence digital design system':
      return { Logo: DefenceDigitalLogo }
    default:
      return {}
  }
}

export type CodeLibrariesProps = Pick<
  GatsbyTypes.MarkdownRemarkFrontmatter,
  'codeLibraries'
>

const CodeLibraries: FC<CodeLibrariesProps> = ({ codeLibraries = [] }) => {
  if (codeLibraries && codeLibraries.length) {
    return (
      <>
        <h2>Key Libraries and Tools Used</h2>
        <Container>
          {codeLibraries.map(lib => {
            const { Logo } = libraryInfoSelector(lib)
            return (
              <CodeLibContainer key={lib}>
                {Logo ? (
                  <LogoContainer>
                    <Logo style={{ maxHeight: '100%', width: '100%' }} />
                  </LogoContainer>
                ) : (
                  <div />
                )}
                <Title>{lib}</Title>
              </CodeLibContainer>
            )
          })}
        </Container>
      </>
    )
  }
  return null
}

export default CodeLibraries
