import styled from '@emotion/styled'

const Description = styled.p`
  color: ${({ theme }) => theme.color.description};
  text-align: center;
  font-size: 1.4em;
  margin-block-start: 0;
`

export default Description
