import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../Layouts/Layout'
import { HTMLContent } from '../components/Content/Content'
import PortfolioPiece from '../modules/PortfolioPiece/PortfolioPiece'

const PortfolioTemplate = ({ data }) => {
  const { markdownRemark } = data || {}
  const { html, frontmatter } = markdownRemark || {}
  const { description, title } = frontmatter || {}

  return (
    <Layout>
      <Helmet titleTemplate="%s | Portfolio">
        <title>{`${title}`}</title>
        <meta name="description" content={`${description}`} />
      </Helmet>
      <PortfolioPiece
        content={html}
        contentComponent={HTMLContent}
        {...frontmatter}
      />
    </Layout>
  )
}

PortfolioTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
}

export default PortfolioTemplate

export const pageQuery = graphql`
  query PortfolioByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        start(formatString: "MM/YYYY")
        end(formatString: "MM/YYYY")
        title
        description
        tags
        role
        codeLibraries
        featureImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
