import React, { FC, ReactNode } from 'react'

export type ContentProps = {
  className?: string
  content?: ReactNode
}

export const HTMLContent: FC<ContentProps> = ({
  content,
  className,
  children,
}) => {
  let html = ''
  if (typeof content === 'string') {
    html = content
  } else if (typeof children === 'string') {
    html = children
  }
  return (
    <div
      className={className}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

const Content: FC<ContentProps> = ({ content, className, children }) => (
  <div className={className}>{content || children}</div>
)

HTMLContent.propTypes = Content.propTypes

export default Content
