import React from 'react'
import styled from '@emotion/styled'

const StyleContainer = styled.div`
  .st0 {
    fill: #ff1a33;
  }
`

const OnsenUI = props => (
  <StyleContainer>
    <svg
      id="OnsenUI_Logo"
      enableBackground="new 0 0 283.5 276.9"
      version="1.1"
      viewBox="0 0 283.5 276.9"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        className="st0"
        d="m221.7 76.1c5 5.1 9.6 11.8 11.5 18.6 9.3 32.6-25.1 70.1-75.6 84.5s-99.4 0.6-108.7-32.1c-1.9-6.8-1.5-14.8 0.1-21.9 2.5-11.3-15.9 13.1-9.2 36.4 10.7 37.8 63.1 52.6 121.6 36s98.1-57.6 87.3-95.4c-6.7-23.3-35.1-34.3-27-26.1z"
      />
      <path
        className="st0"
        d="m222.1 0c-31.7 33.4 14.6 104.8-54.4 138.4 37.2-49.5 8.2-105.6 16.8-122.6s37.6-15.8 37.6-15.8z"
      />
      <path
        className="st0"
        d="m171.3 13.1c-74.6 29.8-18 135.9-51.5 119.9-6-3.5-11.3-9.3-14.1-19.1-5.2-17.6-8.6-62.7 23.8-89-26.1 7.7-49.4 27.5-53.3 50.4-4.1 24 5.7 50-10.4 69.2 33.1 8.6 60.7 11.3 79.8-3.5 2.7-1.8 5.4-4.5 8.2-8.3 16.1-22.7-8.4-81.9 17.5-119.6z"
      />
      <path
        className="st0"
        d="m0 256.5c0-8.2 2.4-14.9 7.1-20 4.8-5.1 10.5-7.6 17.3-7.6 5.8 0 10.4 1.9 14.1 5.6 3.6 3.7 5.4 8.8 5.4 15.1 0 4.5-0.9 8.7-2.7 12.5-1.4 2.9-3.1 5.4-5.2 7.4-2.1 2.1-4.3 3.7-6.7 4.8-3.1 1.5-6.5 2.3-10 2.3-3.7 0-7.1-0.9-10.1-2.6-3-1.8-5.3-4.2-6.9-7.4-1.5-3.2-2.3-6.6-2.3-10.1zm6.1 0.3c0 2.7 0.6 5.2 1.7 7.5s2.8 4 5 5.3 4.6 1.9 7 1.9 4.6-0.6 6.8-1.7c2.1-1.1 4.1-2.7 5.7-4.7 1.7-2 3-4.5 4-7.4s1.5-5.8 1.5-8.6c0-4.6-1.3-8.2-3.9-11-2.6-2.7-5.8-4.1-9.5-4.1-4.8 0-9.1 2.1-12.8 6.2s-5.5 9.6-5.5 16.6z"
      />
      <path
        className="st0"
        d="m48.8 275.8 7-33.5h5.2l-1.2 5.8c2.2-2.3 4.3-4 6.3-5 2-1.1 3.9-1.6 6-1.6 2.7 0 4.8 0.7 6.4 2.2 1.5 1.5 2.3 3.4 2.3 5.9 0 1.2-0.3 3.2-0.8 5.8l-4.2 20.3h-5.7l4.4-21.2c0.4-2.1 0.7-3.6 0.7-4.6 0-1.1-0.4-2-1.1-2.7-0.8-0.7-1.9-1-3.3-1-2.9 0-5.5 1.1-7.8 3.1-2.3 2.1-4 5.7-5 10.8l-3.2 15.6h-6z"
      />
      <path
        className="st0"
        d="m87.3 264.4 5.7-0.4c0 1.6 0.3 3 0.8 4.2s1.4 2.1 2.8 2.8 2.9 1.1 4.7 1.1c2.5 0 4.4-0.5 5.7-1.5s1.9-2.2 1.9-3.6c0-1-0.4-1.9-1.1-2.8-0.8-0.9-2.7-2-5.7-3.3s-4.9-2.2-5.8-2.7c-1.4-0.9-2.5-1.9-3.2-3-0.7-1.2-1.1-2.5-1.1-4 0-2.6 1.1-4.9 3.1-6.8 2.1-1.9 5-2.8 8.8-2.8 4.2 0 7.4 1 9.6 2.9s3.3 4.5 3.4 7.7l-5.6 0.4c-0.1-2-0.8-3.6-2.1-4.8s-3.2-1.8-5.7-1.8c-2 0-3.5 0.5-4.6 1.4s-1.6 1.9-1.6 2.9c0 1.1 0.5 2 1.4 2.8 0.6 0.6 2.3 1.4 4.9 2.5 4.4 1.9 7.1 3.4 8.2 4.5 1.8 1.7 2.7 3.9 2.7 6.4 0 1.7-0.5 3.3-1.5 4.9s-2.6 2.9-4.7 3.8-4.6 1.4-7.4 1.4c-3.9 0-7.1-1-9.9-2.9-2.6-1.9-3.9-5-3.7-9.3z"
      />
      <path
        className="st0"
        d="m146.6 264.4 5.5 0.6c-0.8 2.8-2.6 5.4-5.5 7.8-2.9 2.5-6.3 3.7-10.2 3.7-2.5 0-4.8-0.6-6.8-1.7-2.1-1.2-3.6-2.8-4.7-5s-1.6-4.7-1.6-7.5c0-3.6 0.8-7.2 2.5-10.6s3.9-6 6.6-7.6c2.7-1.7 5.6-2.5 8.7-2.5 4 0 7.2 1.2 9.6 3.7s3.6 5.9 3.6 10.1c0 1.6-0.2 3.3-0.4 5h-24.6c-0.1 0.6-0.1 1.2-0.1 1.8 0 3.1 0.7 5.5 2.2 7.2 1.4 1.7 3.2 2.5 5.3 2.5 2 0 3.9-0.6 5.8-1.9 1.5-1.3 3-3.2 4.1-5.6zm-16.6-8.3h18.8v-1.3c0-2.8-0.7-5-2.2-6.6-1.4-1.5-3.3-2.3-5.5-2.3-2.4 0-4.7 0.8-6.7 2.5-1.9 1.8-3.4 4.4-4.4 7.7z"
      />
      <path
        className="st0"
        d="m159 275.8 7-33.5h5.2l-1.2 5.8c2.2-2.3 4.3-4 6.3-5 2-1.1 3.9-1.6 6-1.6 2.7 0 4.8 0.7 6.4 2.2 1.5 1.5 2.3 3.4 2.3 5.9 0 1.2-0.3 3.2-0.8 5.8l-4.3 20.3h-5.7l4.4-21.2c0.4-2.1 0.7-3.6 0.7-4.6 0-1.1-0.4-2-1.2-2.7s-1.9-1-3.3-1c-2.9 0-5.5 1.1-7.8 3.1-2.3 2.1-4 5.7-5 10.8l-3.2 15.6h-5.8z"
      />
      <path
        className="st0"
        d="m220.5 229.3h9.6l-5.3 25.4-1.3 6.2c-0.1 0.5-0.1 1-0.1 1.5 0 1.9 0.7 3.4 2 4.6 1.4 1.2 3.3 1.8 5.9 1.8 2.3 0 4.2-0.4 5.7-1.3s2.7-2.2 3.5-3.9 1.7-4.6 2.5-8.8l5.4-25.5h9.6l-5.3 25.6c-1.1 5.4-2.4 9.5-3.9 12.3s-3.7 5.2-6.7 6.9c-3 1.8-6.7 2.7-11.3 2.7-5.4 0-9.6-1.3-12.5-4s-4.3-6.1-4.3-10.3c0-0.9 0.1-1.8 0.2-2.8 0.1-0.7 0.5-2.6 1.1-5.8l5.2-24.6z"
      />
      <path className="st0" d="m264.1 276.1 9.8-46.7h9.6l-9.8 46.7h-9.6z" />
    </svg>
  </StyleContainer>
)

export default OnsenUI
