import React, { FC, ReactElement } from 'react'

import CodeLibraries from '../../components/CodeLibraries/CodeLibraries'
import Content from '../../components/Content/Content'
import Description from '../../components/Description/Description'
import { FluidImage } from '../../components/FluidImage/FluidImage'
import PageContainer from '../../components/PageContainer/PageContainer'
import Tags from '../Tags/Tags'
import Title from '../../components/Title/Title'

export type PortfolioPieceProps = Pick<
  GatsbyTypes.MarkdownRemarkFrontmatter,
  | 'description'
  | 'tags'
  | 'title'
  | 'featureImage'
  | 'start'
  | 'end'
  | 'codeLibraries'
  | 'role'
> & {
  content: GatsbyTypes.MarkdownRemark['html'] | ReactElement | null
  contentComponent?: FC
}

const PortfolioPiece: FC<PortfolioPieceProps> = ({
  title,
  content,
  contentComponent,
  description,
  tags = [],
  codeLibraries = [],
  start,
  end,
  featureImage,
  role,
}) => {
  const PostContent = contentComponent || Content
  return (
    <article>
      {title && <Title>{title}</Title>}
      <PageContainer>
        <div>
          {description && <Description>{description}</Description>}
          {!!start && (
            <p style={{ textAlign: 'center' }}>
              Date: {start} - {(end !== 'Invalid date' && end) || 'present'}
            </p>
          )}
          {role && (
            <p style={{ textAlign: 'center' }}>
              <strong>Role: </strong>
              {role}
            </p>
          )}
        </div>
        {featureImage && <FluidImage imageInfo={featureImage} />}
        {tags.length > 0 && <Tags tags={tags} />}
        <div>
          <PostContent content={content} />
          <CodeLibraries codeLibraries={codeLibraries} />
        </div>
      </PageContainer>
    </article>
  )
}

export default PortfolioPiece
