import React from 'react'

export const ElasticSearchLogo = props => (
  <svg viewBox="77,83,410,90" {...props}>
    <path d="m178 102.5h313v41h-313z" fill="none" />
    <g enable-background="new">
      <path d="m193.3 139.4c-4 0-7.2-1.2-9.5-3.5s-3.4-5.6-3.4-9.7c0-4.2 1.1-7.6 3.2-10s5-3.6 8.7-3.6c3.4 0 6.1 1 8.1 3.1s3 4.9 3 8.6v3h-17.3c.1 2.5.8 4.4 2 5.8s3.1 2 5.4 2c1.5 0 3-.1 4.3-.4s2.7-.8 4.3-1.4v4.5c-1.3.6-2.7 1.1-4.1 1.4s-3 .2-4.7.2zm-1.1-22.6c-1.8 0-3.2.6-4.2 1.7s-1.7 2.7-1.9 4.9h11.8c0-2.1-.5-3.8-1.5-4.9s-2.4-1.7-4.2-1.7z" />
      <path d="m215.1 139h-5.5v-36.5h5.5z" />
      <path d="m239.2 139-1.1-3.6h-.2c-1.2 1.6-2.5 2.7-3.8 3.2s-2.9.9-4.9.9c-2.5 0-4.5-.7-6-2.1s-2.1-3.3-2.1-5.8c0-2.7 1-4.7 3-6s5-2.1 9.1-2.2l4.5-.1v-1.4c0-1.7-.4-2.9-1.2-3.7s-2-1.2-3.6-1.2c-1.3 0-2.6.2-3.8.6s-2.4.9-3.5 1.4l-1.8-3.9c1.4-.7 2.9-1.3 4.6-1.7s3.2-.6 4.7-.6c3.3 0 5.8.7 7.5 2.2s2.5 3.7 2.5 6.8v17.2zm-8.2-3.8c2 0 3.6-.6 4.8-1.7s1.8-2.7 1.8-4.7v-2.2l-3.3.1c-2.6.1-4.5.5-5.7 1.3s-1.8 2-1.8 3.6c0 1.2.3 2.1 1 2.7s1.8.9 3.2.9z" />
      <path d="m268.2 131.6c0 2.5-.9 4.5-2.8 5.8s-4.5 2-7.9 2c-3.5 0-6.2-.5-8.3-1.6v-4.8c3 1.4 5.9 2.1 8.5 2.1 3.4 0 5.1-1 5.1-3.1 0-.7-.2-1.2-.6-1.6s-1-.9-1.9-1.4-2.1-1-3.6-1.6c-3-1.2-5-2.3-6.1-3.5s-1.6-2.7-1.6-4.5c0-2.2.9-3.9 2.7-5.2s4.2-1.8 7.3-1.8c3 0 5.9.6 8.6 1.9l-1.8 4.1c-2.8-1.2-5.1-1.7-7.1-1.7-2.9 0-4.4.8-4.4 2.5 0 .8.4 1.5 1.1 2.1s2.4 1.3 5 2.3c2.1.8 3.7 1.6 4.7 2.3s1.7 1.5 2.2 2.4c.7 1 .9 2 .9 3.3z" />
      <path d="m283.8 135c1.3 0 2.7-.2 4-.6v4.1c-.6.3-1.4.5-2.4.7s-2 .3-3 .3c-5.2 0-7.8-2.8-7.8-8.2v-14h-3.5v-2.4l3.8-2 1.9-5.5h3.4v5.8h7.4v4.2h-7.4v13.9c0 1.3.3 2.3 1 2.9s1.6.8 2.6.8z" />
      <path d="m292.7 106.2c0-1 .3-1.7.8-2.3s1.3-.8 2.3-.8 1.7.3 2.3.8.8 1.3.8 2.3c0 .9-.3 1.7-.8 2.2s-1.3.8-2.3.8-1.8-.3-2.3-.8-.8-1.3-.8-2.2zm5.9 32.8h-5.5v-25.9h5.5z" />
      <path d="m316.9 139.4c-3.9 0-6.9-1.1-8.9-3.4s-3.1-5.6-3.1-9.9c0-4.4 1.1-7.7 3.2-10.1s5.2-3.5 9.2-3.5c2.7 0 5.2.5 7.4 1.5l-1.7 4.4c-2.3-.9-4.2-1.4-5.8-1.4-4.5 0-6.7 3-6.7 8.9 0 2.9.6 5.1 1.7 6.6s2.8 2.2 4.9 2.2c2.5 0 4.8-.6 7-1.8v4.8c-1 .6-2 1-3.2 1.2s-2.4.5-4 .5z" />
      <path d="m347.6 131.6c0 2.5-.9 4.5-2.8 5.8s-4.5 2-7.9 2c-3.5 0-6.2-.5-8.3-1.6v-4.8c3 1.4 5.9 2.1 8.5 2.1 3.4 0 5.1-1 5.1-3.1 0-.7-.2-1.2-.6-1.6s-1-.9-1.9-1.4-2.1-1-3.6-1.6c-3-1.2-5-2.3-6.1-3.5s-1.6-2.7-1.6-4.5c0-2.2.9-3.9 2.7-5.2s4.2-1.8 7.3-1.8c3 0 5.9.6 8.6 1.9l-1.8 4.1c-2.8-1.2-5.1-1.7-7.1-1.7-2.9 0-4.4.8-4.4 2.5 0 .8.4 1.5 1.1 2.1s2.4 1.3 5 2.3c2.1.8 3.7 1.6 4.7 2.3s1.7 1.5 2.2 2.4c.7 1 .9 2 .9 3.3z" />
      <path d="m364.9 139.4c-4 0-7.2-1.2-9.5-3.5s-3.4-5.6-3.4-9.7c0-4.2 1.1-7.6 3.2-10s5-3.6 8.7-3.6c3.4 0 6.1 1 8.1 3.1s3 4.9 3 8.6v3h-17.3c.1 2.5.8 4.4 2 5.8s3.1 2 5.4 2c1.5 0 3-.1 4.3-.4s2.7-.8 4.3-1.4v4.5c-1.3.6-2.7 1.1-4.1 1.4s-2.9.2-4.7.2zm-1-22.6c-1.8 0-3.2.6-4.2 1.7s-1.7 2.7-1.9 4.9h11.8c0-2.1-.5-3.8-1.5-4.9s-2.5-1.7-4.2-1.7z" />
      <path d="m397.4 139-1.1-3.6h-.2c-1.2 1.6-2.5 2.7-3.8 3.2s-2.9.9-4.9.9c-2.5 0-4.5-.7-6-2.1s-2.1-3.3-2.1-5.8c0-2.7 1-4.7 3-6s5-2.1 9.1-2.2l4.5-.1v-1.4c0-1.7-.4-2.9-1.2-3.7s-2-1.2-3.6-1.2c-1.3 0-2.6.2-3.8.6s-2.4.9-3.5 1.4l-1.8-3.9c1.4-.7 2.9-1.3 4.6-1.7s3.2-.6 4.7-.6c3.3 0 5.8.7 7.5 2.2s2.5 3.7 2.5 6.8v17.2zm-8.2-3.8c2 0 3.6-.6 4.8-1.7s1.8-2.7 1.8-4.7v-2.2l-3.3.1c-2.6.1-4.5.5-5.7 1.3s-1.8 2-1.8 3.6c0 1.2.3 2.1 1 2.7s1.9.9 3.2.9z" />
      <path d="m422.2 112.6c1.1 0 2 .1 2.7.2l-.5 5.1c-.8-.2-1.6-.3-2.4-.3-2.2 0-4 .7-5.4 2.2s-2.1 3.3-2.1 5.6v13.6h-5.5v-25.9h4.3l.7 4.6h.3c.9-1.5 2-2.8 3.4-3.7s2.9-1.4 4.5-1.4z" />
      <path d="m440.2 139.4c-3.9 0-6.9-1.1-8.9-3.4s-3.1-5.6-3.1-9.9c0-4.4 1.1-7.7 3.2-10.1s5.2-3.5 9.2-3.5c2.7 0 5.2.5 7.4 1.5l-1.7 4.4c-2.3-.9-4.2-1.4-5.8-1.4-4.5 0-6.7 3-6.7 8.9 0 2.9.6 5.1 1.7 6.6s2.8 2.2 4.9 2.2c2.5 0 4.8-.6 7-1.8v4.8c-1 .6-2 1-3.2 1.2s-2.4.5-4 .5z" />
      <path d="m476.3 139h-5.5v-16c0-2-.4-3.5-1.2-4.5s-2.1-1.5-3.8-1.5c-2.3 0-4 .7-5.1 2.1s-1.6 3.7-1.6 6.9v13h-5.5v-36.5h5.5v9.3c0 1.5-.1 3.1-.3 4.8h.4c.8-1.2 1.8-2.2 3.1-2.9s2.9-1 4.7-1c6.3 0 9.4 3.2 9.4 9.5v16.8z" />
    </g>
    <path
      d="m141.8 115.5h-20.9c.1.5.3 1 .4 1.4.7 2.4 1.1 4.9 1.3 7.5.1 1 .1 2.1.1 3.1s0 2.1-.1 3.1c-.2 2.6-.6 5.1-1.3 7.5-.1.5-.3 1-.4 1.4h20.9c6.6 0 11.9-5.4 11.9-12s-5.3-12-11.9-12z"
      fill="#00a9e5"
    />
    <path
      d="m122.6 130.6c.1-1 .1-2.1.1-3.1s0-2.1-.1-3.1c-.2-2.6-.6-5.1-1.3-7.5-.1-.5-.3-1-.4-1.4h-32.3c-1.2 3.8-1.8 7.8-1.8 12s.6 8.2 1.8 12h32.3c.1-.5.3-1 .4-1.4.7-2.4 1.1-4.9 1.3-7.5z"
      fill="#353535"
    />
    <path
      d="m145.4 143.5h-26c-.8 1.8-1.7 3.5-2.7 5.1-1.5 2.5-3.3 4.7-5.3 6.8-.7.7-1.4 1.3-2.1 2-1.4 1.3-3 2.4-4.6 3.5 6.3 4.2 13.9 6.6 22 6.6 13.9 0 26.1-7.1 33.3-17.8-3.7-3.8-8.9-6.2-14.6-6.2z"
      fill="#00bfb3"
    />
    <path
      d="m116.7 148.6c1-1.6 1.9-3.3 2.7-5.1h-29.3c3.1 7.1 8.2 13.2 14.7 17.4 1.6-1.1 3.1-2.2 4.6-3.5.7-.6 1.4-1.3 2.1-2 1.9-2 3.7-4.3 5.2-6.8z"
      fill="#019b8f"
    />
    <path
      d="m111.4 99.6c-.7-.7-1.4-1.3-2.1-2-1.4-1.3-3-2.4-4.6-3.5-6.4 4.2-11.5 10.3-14.7 17.4h29.3c-.8-1.8-1.7-3.5-2.7-5.1-1.4-2.5-3.2-4.8-5.2-6.8z"
      fill="#f9b110"
    />
    <path
      d="m126.7 87.5c-8.1 0-15.7 2.4-22 6.6 1.6 1.1 3.1 2.2 4.6 3.5.7.6 1.4 1.3 2.1 2 2 2.1 3.8 4.3 5.3 6.8 1 1.6 1.9 3.3 2.7 5.1h26c5.7 0 10.9-2.4 14.6-6.2-7.2-10.7-19.4-17.8-33.3-17.8z"
      fill="#fed10a"
    />
  </svg>
)
