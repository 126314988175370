import styled from '@emotion/styled'

const PageContainer = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  align-items: center;
  max-width: 1000px;
  margin: auto;
  padding: 0 1rem;

  @media (min-width: 768px) {
    grid-gap: 3rem;
  }
`
export default PageContainer
