import React from 'react'

export const DefenceDigitalLogo = props => (
  <svg viewBox="0 0 105 110" {...props}>
    <title>MODUKDesignSystem.Logomark.Light.Brand</title>
    <g
      id="Logos"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Artboard-Copy" transform="translate(-100.000000, -634.000000)">
        <g id="Group-3-Copy" transform="translate(100.000000, 634.000000)">
          <path
            d="M68.4306657,50.9197561 L69.0663369,51.372212 L70.1183403,52.1349426 L71.1834486,52.918577 L72.2616441,53.7231138 L73.3529092,54.5485516 L74.4572264,55.3948892 L76.1381364,56.7035792 L77.2750067,57.6021598 L78.4248675,58.5216352 L79.5877013,59.462004 L80.7634903,60.4232648 L81.952217,61.4054163 L83.1538638,62.4084571 L84.9805207,63.9521825 L85.5958474,64.4772008 L86.8361489,65.5429011 L88.0893002,66.629485 L89.3552837,67.7369514 L90.6340817,68.8652986 L91.0998142,69.279698 C82.4026244,85.7225826 69.4317109,99.2960166 52.1870737,110 L51.148551,109.348631 C37.8313956,100.908677 27.1147044,90.7227265 18.9984772,78.7907805 C39.7767579,73.3798063 56.1037822,64.292051 67.9052657,51.4952271 L68.4306657,50.9197561 Z"
            id="Path"
            fill="#4368F5"
          ></path>
          <path
            d="M47.2647805,0.0442440175 C40.0777884,23.0908685 39.1251186,43.4857403 44.4961512,61.1809404 C36.0803522,66.0324559 26.2821433,69.8263613 15.0882511,72.5569155 C5.02890213,55.1396729 0,34.3452041 0,10.1742451 C12.0790161,3.98246216 27.5995081,0.612363165 46.5614758,0.0631529598 L47.2647805,0.0442440175 Z"
            id="Path"
            fill="#E84651"
          ></path>
          <path
            d="M104.374147,10.1742451 C104.374147,29.9561869 101.005751,47.4765299 94.2689568,62.735274 L93.2385263,61.8305054 L91.9310225,60.6922727 L90.6362589,59.5753956 L89.354218,58.4798728 L88.0848822,57.4057028 L86.8282339,56.3528842 L85.5842555,55.3214157 L83.7420054,53.8142413 L81.9281637,52.3550968 L80.734689,51.4090147 L79.5537961,50.4842758 L78.3854677,49.5808787 L77.2296859,48.6988219 L76.0864334,47.8381041 L74.67496,46.7922127 L73.2830015,45.7796586 L72.1834625,44.9936164 L71.0963734,44.2289069 L70.0217167,43.4855288 L68.9594748,42.7634807 L68.4330039,42.410455 L67.3893513,41.7203993 L66.8721651,41.383369 L65.5927158,40.5641179 L64.3325478,39.7781858 L63.8338717,39.4731417 L62.8457492,38.8790444 L62.3562984,38.589991 L61.3866068,38.0278737 L60.9063616,37.7548095 L59.9550612,37.224669 L59.4840017,36.9675924 L58.5510531,36.4694256 C58.1391245,36.2527547 57.7308144,36.0423991 57.3261158,35.8383582 L56.7217758,35.5370328 L55.8254119,35.1028041 L54.9412076,34.6898853 C52.5630006,33.6004479 50.3183815,32.7454476 48.2055417,32.1249939 C48.7440886,22.1742099 50.8806812,11.4681639 54.6280012,0 C75.0441961,0.247573359 91.625873,3.63939593 104.374147,10.1742451 Z"
            id="Path"
            fill="#5CC2E7"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
