import React from 'react'
import reactTestingLibraryLogo from './reactTestingLibrary.png'

const ReactTestingLibraryLogo = props => (
  <img
    src={reactTestingLibraryLogo}
    alt="React Testing Library Logo"
    {...props}
  />
)

export default ReactTestingLibraryLogo
