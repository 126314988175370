import React, { FC } from 'react'

import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

export type FluidImageProps = {
  imageInfo: string | GatsbyTypes.ChildImageSharp
  className?: string
}

export const FluidImage: FC<FluidImageProps> = ({ imageInfo, className }) => {
  if (!imageInfo) {
    return null
  }
  // for Netlify CMS
  if (typeof imageInfo === 'string') {
    return <img className={className} src={imageInfo} alt="alt-not-available" />
  }
  const { alt = '', childImageSharp, image } = imageInfo

  // if (!!image && !!image.childImageSharp) {
  //   return (
  //     <GatsbyImage
  //       image={image.childImageSharp.gatsbyImageData}
  //       className={className}
  //       alt={alt}
  //     />
  //   )
  // }
  if (childImageSharp) {
    return (
      <GatsbyImage
        image={childImageSharp.gatsbyImageData}
        className={className}
        alt={alt}
      />
    )
  }

  return null
}

FluidImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.object,
  }).isRequired,
}
